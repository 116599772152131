<template>
  <div class="footer-wrapper">
    <client-only>
      <footer class="container is-max-desktop">
        <div class="footer-content mx-4 pt-5 pb-1">
          <div class="columns is-flex-desktop-only is-justify-content-space-between">
            <div class="column is-one-quarter">
              <img :alt="$t('footer.alt.logo')" src="~assets/images/memodio_logos/logo_text_white.svg" />
              <hr class="mt-2 mb-4" />
              <img
                alt="Ce badge"
                class="ce_badge"
                src="~assets/images/landing/ce_badge_footer.webp"
                sizes="sm:100vw md:100vw lg:100vw"
              />
              <div class="social-icons">
                <a href="https://instagram.com/memodio.app?igshid=YmMyMTA2M2Y=" target="_blank">
                  <img
                    :alt="$t('footer.alt.instagram')"
                    src="~assets/images/footer/instagram_icon.webp"
                    height="48px"
                    width="48px"
                  />
                </a>
                <a href="https://www.linkedin.com/company/memodio-app/" target="_blank">
                  <img
                    :alt="$t('footer.alt.linkedIn')"
                    src="~assets/images/footer/linkedin_icon.webp"
                    height="48px"
                    width="48px"
                  />
                </a>
                <a href="https://linktr.ee/memodio.app" target="_blank">
                  <img
                    :alt="$t('footer.alt.linkTree')"
                    src="~assets/images/footer/linktree_icon.webp"
                    height="48px"
                    width="48px"
                  />
                </a>
              </div>
            </div>
            <div class="column footer-links">
              <ul class="has-text-white columns is-size-5">
                <li class="column become-vertical-on-mobile">
                  <nuxt-link class="text-alignment" :to="localePath('contact')">
                    {{ $t("footer.contact") }}
                  </nuxt-link>

                  <nuxt-link class="text-alignment" :to="localePath('dataProtection')">
                    {{ $t("footer.dataProtection") }}
                  </nuxt-link>

                  <a :href="`${memodioCdnUrl()}/app-content/other/manual.pdf`" target="_blank" class="text-alignment">
                    {{ $t("footer.manual") }}
                  </a>
                </li>
                <li class="column become-vertical-on-mobile has-text-left-mobile has-text-left-tablet-only">
                  <nuxt-link class="text-alignment" :to="localePath('imprint')">
                    {{ $t("footer.imprint") }}
                  </nuxt-link>
                  <nuxt-link class="text-alignment" :to="localePath('supporters')">
                    {{ $t("footer.supporters") }}
                  </nuxt-link>
                  <nuxt-link class="text-alignment" :to="localePath('aok-plus')"> AOK PLUS </nuxt-link>
                  <nuxt-link class="text-alignment" :to="localePath('aok-rps')"> AOK RPS </nuxt-link>
                  <nuxt-link class="text-alignment" :to="localePath('mobil-krankenkasse')">
                    Mobil Krankenkasse
                  </nuxt-link>
                </li>
                <li class="column become-vertical-on-mobile has-text-left-mobile has-text-left-tablet-only">
                  <nuxt-link :to="localePath('about') + '#jobs-section'">
                    {{ $t("footer.jobs") }}
                  </nuxt-link>
                  <a :href="`${memodioCdnUrl()}/app-content/other/agb.pdf`" target="_blank"> AGB </a>
                </li>
                <li class="column has-text-left-mobile has-text-left-tablet-only">
                  <LanguageToggle class="languageToggle" />
                </li>
              </ul>
            </div>
          </div>
          <div class="has-text-white is-size-7">Copyright © {{ currentYear }} memodio GmbH. All rights reserved.</div>
        </div>
      </footer>
    </client-only>
  </div>
</template>

<script>
import { MEMODIO_CDN_URL } from "~/services/constants"
import LanguageToggle from "~/components/LanguageToggle.vue"

export default {
  name: "MemodioFooter",
  components: {
    LanguageToggle,
  },
  scrollToTop: true,
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
  methods: {
    memodioCdnUrl() {
      return MEMODIO_CDN_URL
    },
    toggleLanguage() {
      const currentLocale = this.$i18n.locale
      this.$root.$i18n.locale = this.$i18n.locales.filter(locale => locale.code !== currentLocale)[0].code
    },
  },
}
</script>

<style lang="scss" scoped>
.text-alignment {
  text-align: left;
}

.become-vertical-on-mobile {
  @media screen and (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    padding: 0;

    a {
      padding: 12px 12px 12px 24px;
    }
  }

  @media screen and (min-width: $tablet) {
    display: flex;
    flex-direction: column;
    padding: 12px;
    a:not(:first-child) {
      padding-top: 12px;
    }
  }
}
.footer-wrapper {
  background-color: $brandpurple;
  z-index: 9999;
}

a {
  color: white;
}

.footer-links {
  @media screen and (min-width: $tablet) {
    margin-left: 4%;
  }
  padding-top: 16px;
  font-weight: 500;
}

.social-icons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  gap: 24px;

  img {
    max-height: 48px;
    max-width: 48px;
    padding: 0;
  }
  @media screen and (min-width: $tablet) {
    height: 50%;
    width: 50%;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    gap: 32px;
  }
}

li.column {
  text-align: center;
}

.ce_badge {
  margin-top: 0;
  width: 100%;
}

.languageToggle {
  margin-top: -8px;
}
</style>
