import { ref, watch } from 'vue'

<script setup>
const { locale, setLocale } = useI18n()
const isEnglishSelected = ref(locale.value === "en")

const toggleLanguage = () => {
  const nextLocale = locale.value === "en" ? "de" : "en"
  setLocale(nextLocale)
}
</script>

<template>
  <div>
    <label class="switch">
      <input v-model="isEnglishSelected" name="language-toggle" type="checkbox" @change="toggleLanguage" />
      {{ isEnglishSelected }}
      <div class="slider round">
        <div class="text-wrapper is-size-4">
          <div class="de">DE</div>
          <div class="en">EN</div>
        </div>
      </div>
    </label>
  </div>
</template>

<style lang="scss" scoped>
$width: 112px;
$height: 48px;
.text-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $height;
  width: $width;

  .de {
    padding-left: 12px;
    z-index: 9;
    color: white;
    transition: color 0s;
  }
  .en {
    padding-right: 12px;
    z-index: 9;
    color: $brandpurple;
    transition: color 0.4s;
  }
}

.switch {
  position: relative;
  display: inline-block;
  height: $height;
  width: $width;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 40px;
  width: 48px;
  left: 4px;
  bottom: 4px;
  background-color: $brandpurple;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: white;
  .de {
    color: $brandpurple;
  }
  .en {
    color: white;
  }
}

input:focus + .slider {
  box-shadow: 0 0 1px white;
}

input:checked + .slider:before {
  -webkit-transform: translateX(56px);
  -ms-transform: translateX(56px);
  transform: translateX(56px);
}

.slider.round {
  border-radius: 16px;
}

.slider.round:before {
  border-radius: 35%;
}
</style>
