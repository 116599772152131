<script setup>
import CookieExplanationGroup from "./CookieExplanationGroup.vue"
import BasicModal from "~/components/utils/BasicModal.vue"
</script>

<template>
  <div v-if="isVisible" class="modal-mask">
    <BasicModal :is-fullscreen="true">
      <div class="modal-head">
        <h3 class="is-size-3 pb-4">
          {{ $t("cookiePreferences.privacyPreference") }}
        </h3>
        <div>
          {{ $t("cookiePreferences.privacyExplanation") }}
        </div>
        <div class="buttons-top">
          <div class="button is-brandpurple is-rounded is-size-5" @click="acceptCurrentSelection">
            {{ $t("cookiePreferences.save") }}
          </div>
          <div class="button is-brandpurple is-rounded is-size-5" @click="acceptAll">
            {{ $t("cookiePreferences.acceptAll") }}
          </div>
        </div>
      </div>

      <div class="modal-core">
        <CookieExplanationGroup
          v-for="explanation in cookieExplanations"
          :id="explanation.id"
          :key="explanation.name"
          :cookie-names="explanation.cookieNames"
          :expiry="explanation.expiry"
          :explanation="explanation.explanation"
          :is-necessary="explanation.isNecessary"
          :name="explanation.name"
          :policy-link="explanation.policyLink"
          :purpose="explanation.purpose"
          :title="explanation.title"
          @value-changed="updateCookieAcceptance"
        >
        </CookieExplanationGroup>
      </div>

      <div class="modal-footer">
        <div class="button is-brandpurple is-rounded is-size-5" @click="acceptAll">
          {{ $t("cookiePreferences.acceptAll") }}
        </div>
      </div>
    </BasicModal>
    <div v-show="false" class="modal-wrapper">
      <div class="modal-container has-text-primary">
        <div class="modal-head">
          <h3 class="is-size-3 pb-4">
            {{ $t("cookiePreferences.privacyPreference") }}
          </h3>
          <div>
            {{ $t("cookiePreferences.privacyExplanation") }}
          </div>
          <div class="buttons-top">
            <div class="button is-brandpurple is-rounded is-size-5" @click="acceptCurrentSelection">
              {{ $t("cookiePreferences.save") }}
            </div>
            <div class="button is-brandpurple is-rounded is-size-5" @click="acceptAll">
              {{ $t("cookiePreferences.acceptAll") }}
            </div>
          </div>
        </div>

        <div class="modal-core">
          <CookieExplanationGroup
            v-for="explanation in cookieExplanations"
            :id="explanation.id"
            :key="explanation.name"
            :cookie-names="explanation.cookieNames"
            :expiry="explanation.expiry"
            :explanation="explanation.explanation"
            :is-necessary="explanation.isNecessary"
            :name="explanation.name"
            :policy-link="explanation.policyLink"
            :purpose="explanation.purpose"
            :title="explanation.title"
            @value-changed="updateCookieAcceptance"
          >
          </CookieExplanationGroup>
        </div>

        <div class="modal-footer">
          <div class="button is-brandpurple is-rounded is-size-5" @click="acceptAll">
            {{ $t("cookiePreferences.acceptAll") }}
          </div>
        </div>

        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookiePreferences",
  props: {
    isVisible: { type: Boolean, default: false },
  },
  emits: ["acceptAll", "acceptVideo", "acceptStatistics", "acceptNecessary"],
  data() {
    return {
      acceptedValues: {
        statistics: false,
        video: false,
        necessary: true,
      },
    }
  },
  computed: {
    cookieExplanations() {
      return [
        {
          title: this.$t("cookiePreferences.necessaryTitle"),
          name: this.$t("cookiePreferences.necessaryName"),
          explanation: this.$t("cookiePreferences.necessaryExplanation"),
          purpose: this.$t("cookiePreferences.necessaryPurpose"),
          policyLink: "https://memodio-app.com/datenschutz",
          cookieNames: "cookieAcceptance, language",
          expiry: this.$t("cookiePreferences.necessaryExpiry"),
          isNecessary: true,
          id: "necessary",
        },
        {
          title: this.$t("cookiePreferences.statisticsTitle"),
          name: this.$t("cookiePreferences.statisticsName"),
          explanation: this.$t("cookiePreferences.statisticsExplanation"),
          purpose: this.$t("cookiePreferences.statisticsPurpose"),
          policyLink: "https://policies.google.com/privacy",
          cookieNames: "_ga, _ga_[container-id], _gid, _gat_gtag_[property_id]",
          expiry: "2 Years, 2 Years, 24 hours, immediately",
          id: "statistics",
        },
        {
          title: this.$t("cookiePreferences.videoTitle"),
          name: this.$t("cookiePreferences.videoName"),
          explanation: this.$t("cookiePreferences.videoExplanation"),
          purpose: this.$t("cookiePreferences.videoPurpose"),
          policyLink: "https://vimeo.com/cookie_policy",
          cookieNames: "sync_active, player",
          expiry: this.$t("cookiePreferences.videoExpiry"),
          id: "video",
        },
      ]
    },
  },
  methods: {
    acceptAll() {
      this.$emit("acceptAll")
    },
    acceptCurrentSelection() {
      if (this.areAllCookiesAccepted()) {
        this.$emit("acceptAll")
      } else if (this.areOnlyVideoCookiesAccepted()) {
        this.$emit("acceptVideo")
      } else if (this.areOnlyStatisticsCookiesAccepted()) {
        this.$emit("acceptStatistics")
      } else {
        this.$emit("acceptNecessary")
      }
    },
    updateCookieAcceptance(args) {
      const key = args[0]
      const newValue = args[1]
      this.acceptedValues[key] = newValue
    },
    areAllCookiesAccepted() {
      return this.acceptedValues.video && this.acceptedValues.statistics
    },
    areOnlyVideoCookiesAccepted() {
      return this.acceptedValues.video && !this.acceptedValues.statistics
    },
    areOnlyStatisticsCookiesAccepted() {
      return !this.acceptedValues.video && this.acceptedValues.statistics
    },
  },
}
</script>

<style scoped>
.modal-footer {
  margin-top: 1rem;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width: 900px;
  max-height: 98%;
  margin: 100px auto 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  border-radius: 20px;
  overflow-y: scroll;
}

.modal-head {
  padding-top: 1rem;
}

.modal-core {
  margin: 10px 0;
}

.modal-enter-active .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.invisible {
  visibility: hidden;
  display: none;
}

.buttons-top {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.buttons-top > button {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0px;
  margin: 0 8px 0 8px;
}

@media (max-width: 482px) {
  .buttons {
    flex-direction: column;
    align-items: center;
  }

  .buttons > button {
    margin-bottom: 0.4rem;
  }
}
</style>
