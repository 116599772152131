<template>
  <div class="cookie-group">
    <div class="cookie-group-head">
      <h4>{{ title }}</h4>
      <div class="accepted-checkbox">
        <span>{{ isAccepted ? $t("cookiePreferences.on") : $t("cookiePreferences.off") }}</span>
        <input v-model="isAccepted" :disabled="isNecessary" class="checkbox" type="checkbox" />
      </div>
    </div>
    <div>
      {{ explanation }}
    </div>
    <div class="more-info-button mt-2" @click="toggleMoreInfo">
      {{ $t("cookiePreferences.moreInfo") }}
    </div>
    <div v-if="moreInfoShown">
      <table>
        <div class="table-padding"></div>
        <tr>
          <td class="category">{{ $t("cookiePreferences.accepted") }}</td>
          <td>{{ isAccepted ? $t("cookiePreferences.yes") : $t("cookiePreferences.no") }}</td>
        </tr>
        <tr>
          <td class="category">{{ $t("cookiePreferences.name") }}</td>
          <td>{{ name }}</td>
        </tr>
        <tr>
          <td class="category">{{ $t("cookiePreferences.purpose") }}</td>
          <td>{{ purpose }}</td>
        </tr>
        <tr>
          <td class="category">{{ $t("cookiePreferences.privacyPolicy") }}</td>
          <td>
            <a :href="policyLink" target="_blank">
              {{ policyLink }}
            </a>
          </td>
        </tr>
        <tr>
          <td class="category">{{ $t("cookiePreferences.cookieNames") }}</td>
          <td>{{ cookieNames }}</td>
        </tr>
        <tr>
          <td class="category">{{ $t("cookiePreferences.cookieExpiry") }}</td>
          <td>{{ expiry }}</td>
        </tr>

        <div class="table-padding"></div>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieExplanationGroup",
  props: {
    id: { type: String, required: true },
    title: { type: String, required: true },
    explanation: { type: String, required: true },
    name: { type: String, required: true },
    cookieNames: { type: String, required: true },
    purpose: { type: String, required: true },
    policyLink: { type: String, required: true },
    expiry: { type: String, required: true },
    isNecessary: { type: Boolean, default: false },
  },
  emits: ["valueChanged"],
  data() {
    return {
      moreInfoShown: false,
      isAccepted: false,
    }
  },
  watch: {
    isAccepted() {
      this.$emit("valueChanged", [this.id, this.isAccepted])
    },
  },
  mounted() {
    if (this.isNecessary) {
      this.isAccepted = true
    }
  },
  methods: {
    toggleMoreInfo() {
      this.moreInfoShown = !this.moreInfoShown
    },
  },
}
</script>

<style lang="scss" scoped>
.checkbox {
  background-color: $brandpurple;
}

.table-padding {
  margin-top: 1rem;
}

.more-info-button {
  cursor: pointer;
  color: $brandpurple;
}
.more-info-button:hover {
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

.accepted-checkbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

input {
  margin-left: 0.3rem;
}

.category {
  font-weight: 800;
}

table {
  margin-top: 1.5rem;
  background-color: white;
  border-radius: 20px;
  width: 100%;
}

td {
  padding: 0.5rem 2rem;
}

@media (max-width: 600px) {
  td {
    max-width: 120px;
    word-wrap: break-word; /* All browsers since IE 5.5+ */
    overflow-wrap: break-word;
  }
}

.cookie-group {
  display: flex;
  flex-direction: column;
  background-color: #e7e7e7;
  padding: 1.8rem 2rem;
  border-radius: 20px;
  margin-top: 1.2rem;
  max-width: 90vw;
}

.cookie-group-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
