<script setup lang="ts">
import CookiePreferences from "~/components/CookiePreferences.vue"
import modalEventBus from "~/services/modalEventBus"
import {
  getCookieAcceptanceLevel,
  acceptAllCookies,
  acceptVideoCookies,
  acceptStatisticsCookies,
  acceptNecessaryCookies,
} from "~/composables/useAcceptanceCookie"

const localePath = useLocaleRoute()

const isVisible = ref(false)
const arePreferencesVisible = ref(false)
const areStatisticsAccepted = ref(false)
const areVideosAccepted = ref(false)

onMounted(() => {
  setVisibilityByCookieAcceptance()
  modalEventBus.on("open-modal", () => {
    isVisible.value = true
  })
})

// methods
function setVisibilityByCookieAcceptance() {
  const cookieAcceptanceLevel = getCookieAcceptanceLevel()
  if (cookieAcceptanceLevel === undefined || cookieAcceptanceLevel === null || cookieAcceptanceLevel.name === "none") {
    isVisible.value = true
  }
}

function saveCookies() {
  isVisible.value = false
  if (areStatisticsAccepted.value && areVideosAccepted.value) {
    acceptAllCookies()
  } else if (areStatisticsAccepted.value && !areVideosAccepted.value) {
    acceptStatisticsCookies()
  } else if (!areStatisticsAccepted.value && areVideosAccepted.value) {
    acceptVideoCookies()
  } else {
    acceptNecessaryCookies()
  }
  closeAndReloadPage()
}

function selectNecessaryAndSave() {
  areVideosAccepted.value = false
  areStatisticsAccepted.value = false
  saveCookies()
}

function selectAllAndSave() {
  areVideosAccepted.value = true
  areStatisticsAccepted.value = true
  saveCookies()
}

function closeAndReloadPage() {
  isVisible.value = false
  reloadNuxtApp()
}
function makePreferencesVisible() {
  arePreferencesVisible.value = true
}
</script>

<template>
  <div v-if="isVisible" id="cookie-banner">
    <h1 class="mb-2 is-size-5">
      {{ $t("cookiePreferences.headline") }}
    </h1>
    <div class="cookie-text">
      {{ $t("cookiePreferences.details") }}
      <nuxt-link :to="localePath('dataProtection')" class="is-underlined">
        {{ $t("cookiePreferences.dataProtection") }}.
      </nuxt-link>
    </div>
    <div class="options">
      <span class="option">
        <input checked class="checkbox" aria-label="Essential cookies" disabled type="checkbox" />
        {{ $t("cookiePreferences.essentialCookies") }}
      </span>
      <span class="option">
        <input v-model="areStatisticsAccepted" class="checkbox" aria-label="Statistics" type="checkbox" />
        {{ $t("cookiePreferences.statistics") }}
      </span>
      <span class="option">
        <input v-model="areVideosAccepted" aria-label="Videos" class="checkbox" type="checkbox" />
        {{ $t("cookiePreferences.videos") }}
      </span>
    </div>
    <div class="buttons">
      <div class="button is-brandpurple is-rounded is-size-5 is-size-6-mobile my-2" @click="saveCookies">
        {{ $t("cookiePreferences.save") }}
      </div>

      <div class="button is-brandpurple is-rounded is-size-5 is-size-6-mobile my-2" @click="selectNecessaryAndSave">
        {{ $t("cookiePreferences.decline") }}
      </div>

      <div class="button is-brandpurple is-rounded is-size-5 is-size-6-mobile my-2" @click="selectAllAndSave">
        {{ $t("cookiePreferences.accept") }}
      </div>
    </div>
    <div class="gray-link" @click="makePreferencesVisible">
      {{ $t("cookiePreferences.individualPreferences") }}
    </div>

    <CookiePreferences
      :is-visible="arePreferencesVisible"
      @accept-all="acceptAllCookies"
      @accept-necessary="acceptNecessaryCookies"
      @accept-statistics="acceptStatisticsCookies"
      @accept-video="acceptVideoCookies"
    />
  </div>
</template>

<style lang="scss" scoped>
h1 {
  font-weight: normal;
}
#cookie-banner {
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  padding: 2rem 2rem;
  max-width: 580px;
  background-color: white;
  border-style: solid;
  border-color: $brandpurple;
  color: $brandpurple;
  position: fixed;
  bottom: 2rem;
  right: 4rem;
  border-radius: 15px;
  z-index: 99;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation-name: appearFromBottom;
}

.options {
  max-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto auto 1.5rem;
  @media (max-width: 420px) {
    flex-direction: column;
    width: 60%;
    .option {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }
}

.option {
  flex: 1 1 0;
  display: flex;
  align-items: center;
  margin: 0 0.5rem;
}

.option > input {
  margin-right: 8px;
}

.cookie-text {
  margin-bottom: 1rem;
}

button.button {
  margin: 0 0.25rem;
}

.buttons {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  .button {
    width: 60%;
  }
}

.gray-link {
  color: $brandpurple;
  font-size: 14px;
  text-decoration: underline;
  margin: 16px auto 0 auto;
}

.gray-link:hover {
  cursor: pointer;
  color: $brandpurple;
  font-size: 14px;
  font-weight: 900;
  text-decoration: underline;
  margin: 16px auto 0 auto;
}

@keyframes appearFromBottom {
  0% {
    transform: translateY(200px);
    filter: opacity(0);
  }
  100% {
    transform: translateY(0px);
    filter: opacity(1);
  }
}

@media (max-width: 680px) {
  #cookie-banner {
    left: 0;
    width: 100%;
    bottom: 0;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .button-row {
    flex-direction: column;
    align-items: center;
  }

  .button-row > button {
    margin-bottom: 0.5rem;
  }
  button {
    width: 60%;
  }
}
</style>
