<template>
  <div class="navbar-wrapper">
    <div class="navbar-desktop" :class="{ waitlist: isCurrentCtaPage }">
      <nav
        class="navbar container is-max-desktop is-size-4 has-text-primary is-align-center"
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand">
          <NuxtLink class="navbar-item pl-0" :to="localePath('/')">
            <img
              v-if="!isCurrentCtaPage"
              id="nav-logo"
              :alt="$t('nav.alt.logoBlack')"
              class="image"
              src="~assets/images/memodio_logos/logo_text_black.svg"
            />
            <img
              v-else
              id="nav-logo"
              class="image"
              :alt="$t('nav.alt.logoWhite')"
              src="~assets/images/memodio_logos/logo_text_white.svg"
            />
          </NuxtLink>
        </div>
        <div class="custom-navbar-burger pr-0" :class="{ 'has-text-white': isCurrentCtaPage }" @click="toggleMenu">
          {{ $t("nav.burger") }}
        </div>
        <div id="memodio-navbar" class="navbar-menu">
          <div class="navbar-start">
            <NuxtLink class="navbar-item" :class="{ 'has-text-white': isCurrentCtaPage }" :to="localePath('/')">
              {{ $t("nav.start") }}
            </NuxtLink>
            <NuxtLink
              class="navbar-item"
              :class="{ 'has-text-white': isCurrentCtaPage }"
              :to="localePath({ name: 'about' })"
            >
              {{ $t("nav.about") }}
            </NuxtLink>
            <NuxtLink
              class="navbar-item"
              :class="{ 'has-text-white': isCurrentCtaPage }"
              :to="localePath({ name: 'press' })"
            >
              {{ $t("nav.press") }}
            </NuxtLink>
            <NuxtLink class="navbar-item" :class="{ 'has-text-white': isCurrentCtaPage }" :to="localePath('/blog')">
              {{ $t("nav.guide") }}
            </NuxtLink>
          </div>
          <div v-show="!isCurrentRouteStudyPage" class="navbar-end">
            <a :href="appstoresForwardingUrl" target="_blank" class="navbar-item pr-0">
              <button
                class="button is-brandpurple is-rounded is-large btn"
                :class="{ 'is-inverted': isCurrentCtaPage }"
              >
                {{ $t("nav.appstoresLink") }}
              </button>
            </a>
          </div>
        </div>
      </nav>
    </div>
    <div v-show="isMobileVisible" class="navbar-mobile-wrapper">
      <nav
        class="mobile-menu container is-max-desktop is-size-4 has-text-primary is-align-center"
        :class="{
          'waitlist-mobile': isCurrentCtaPage,
          'not-waitlist': !isCurrentCtaPage,
          'expanded-mobile-menu': isExpanded,
        }"
        role="navigation"
        aria-label="mobile navigation expansion"
      >
        <div class="nav-links-mobile">
          <nuxt-link
            class="navbar-item mobile-navbar-item"
            :class="{ 'has-text-white': isCurrentCtaPage }"
            :to="localePath('/')"
            @click="toggleMenu"
          >
            {{ $t("nav.start") }}
          </nuxt-link>

          <nuxt-link
            class="navbar-item mobile-navbar-item"
            :class="{ 'has-text-white': isCurrentCtaPage }"
            :to="localePath({ name: 'about' })"
            @click="toggleMenu"
          >
            {{ $t("nav.about") }}
          </nuxt-link>
          <nuxt-link
            class="navbar-item mobile-navbar-item"
            :class="{ 'has-text-white': isCurrentCtaPage }"
            :to="localePath({ name: 'press' })"
            @click="toggleMenu"
          >
            {{ $t("nav.press") }}
          </nuxt-link>

          <nuxt-link
            class="navbar-item mobile-navbar-item"
            :class="{ 'has-text-white': isCurrentCtaPage }"
            :to="localePath('/blog')"
            @click="toggleMenu"
          >
            {{ $t("nav.guide") }}
          </nuxt-link>
        </div>

        <a :href="appstoresForwardingUrl" target="_blank">
          <button
            class="button is-brandpurple is-rounded is-medium btn mb-4 mt-4"
            :class="{ 'is-inverted': isCurrentCtaPage }"
            @click="toggleMenu"
          >
            {{ $t("nav.appstoresLink") }}
          </button>
        </a>
      </nav>
    </div>
  </div>
</template>

<script setup>
import { APPSTORES_FORWARDING_URL } from "~/services/constants"
const localePath = useLocaleRoute()
const currentRoute = useRoute()
const isCurrentCtaPage = computed(() => {
  return currentRoute.fullPath.toString().includes("cta")
})
const isCurrentRouteStudyPage = computed(() => {
  return currentRoute.fullPath.toString().includes("studie")
})
console.log(isCurrentRouteStudyPage)
</script>

<script>
export default {
  name: "NavigationBar",
  data() {
    return {
      isExpanded: false,
      isMobileVisible: true,
      appstoresForwardingUrl: APPSTORES_FORWARDING_URL,
    }
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales.filter(i => i.code !== this.$i18n.locale)
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll)
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll)
  },
  methods: {
    toggleMenu() {
      this.isMobileVisible = true
      this.isExpanded = !this.isExpanded
    },
  },
}
</script>

<style lang="scss" scoped>
#nav-logo {
  width: auto;
  max-width: 200px;
}

.mobile-navbar-item {
  margin-top: 8px;
}

.btn {
  font-weight: 600;
}

.navbar-desktop {
  padding-left: 16px;
  padding-right: 16px;

  @media screen and (min-width: $navbar-breakpoint) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  @media screen and (max-width: $navbar-breakpoint) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  background-color: white;
  z-index: 10;
  position: relative;
}

.waitlist {
  background-color: $brandpurple;
}

.waitlist-mobile {
  background-color: $brandpurple-lower-opacity;
}

.not-waitlist {
  background-color: rgba(255, 255, 255, 0.95);
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.is-align-center {
  align-items: center !important;
}

.mobile-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: -200px;
  left: 0;
  width: 100%;
  z-index: 2;
  transition: top 0.6s;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;

  .router-link-active {
    border-radius: 24px;
    background-color: $navbackground;
  }
}

.expanded-mobile-menu {
  top: 88px;
}

.navbar-brand {
  flex-shrink: 1;

  .navbar-item {
    padding-top: 0;
    flex-shrink: 1;
  }
}

.custom-navbar-burger {
  padding: 0 24px;
  cursor: pointer;
  @media screen and (min-width: $navbar-breakpoint) {
    display: none;
  }
  @media screen and (max-width: $navbar-breakpoint) {
    display: flex;
    justify-content: flex-end;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  max-height: 4rem;
  background-color: transparent;
  z-index: 10;
}

.navbar-menu {
  flex-grow: initial !important;
  flex-shrink: initial !important;
}

.navbar-item {
  padding: 0 24px;

  .nuxt-link-exact-active {
    border-radius: 24px;
    background-color: $navbackground;
  }
}

#memodio-navbar {
  .navbar-start {
    .router-link-active {
      border-radius: 24px;
      background-color: $navbackground;
    }

    .navbar-item {
      margin-left: 8px;
      margin-right: 8px;
    }

    .nuxt-link-exact-active {
      border-radius: 24px;
      background-color: $navbackground;
    }
  }
}
</style>
